import React from 'react';
import { graphql, Link } from 'gatsby';

import { Helmet } from 'react-helmet';

import { useLocale } from '@app/hooks';

import { Author, BlogCard, Breadcrumb, Layout, Sidebar } from '@app/components';
import Icons from '@app/icons';

type PostsPagePropTypes = {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            title: string;
            path: string;
            brief: string;
            image: any;
          };
        };
      }[];
    };
    recentPost: {
      edges: {
        node: {
          frontmatter: {
            title: string;
            path: string;
            brief: string;
          };
        };
      }[];
    };
  };
  pageContext: {
    numberOfPages: number;
    previousPagePath: string;
    nextPagePath: string;
  };
};

const PostsPage: React.FC<PostsPagePropTypes> = ({ data, pageContext }) => {
  const SCOPE_OPTIONS = {
    scope: 'pages.Posts',
  };
  const { t } = useLocale();

  const Posts = data.allMarkdownRemark.edges.map(({ node }) => (
    <BlogCard
      title={node.frontmatter.title}
      image={node.frontmatter.image?.childImageSharp.fluid.src}
      description={node.frontmatter.brief}
      path={node.frontmatter.path}
      key={node.frontmatter.title}
    />
  ));

  return (
    <Layout>
      <Helmet title={t('labels.pageTitle', SCOPE_OPTIONS)} />
      <Breadcrumb
        items={[
          { path: '/', name: t('labels.home', SCOPE_OPTIONS) },
          { path: '/posts', name: t('labels.blog', SCOPE_OPTIONS) },
        ]}
      />

      <div className="container mx-auto px-1.5 mt-10">
        <Author />
      </div>

      <div className="container mx-auto px-1.5 my-20">
        <div className="grid lg:grid-cols-12 gap-6 items-start justify-between">
          <div className="lg:col-span-10">
            {Posts}

            <div className="pagination">
              <Link
                to={pageContext.previousPagePath}
                className="pagination__item pagination__item--prev"
              >
                <Icons.ChevronLeft />
              </Link>
              {[...Array(pageContext.numberOfPages)].map((_, index) => {
                const pageNumber = index + 1;
                return (
                  <Link
                    to={pageNumber === 1 ? '/posts' : `/posts/${pageNumber}`}
                    className="pagination__item"
                    key={index}
                  >
                    {pageNumber}
                  </Link>
                );
              })}
              <Link
                to={pageContext.nextPagePath}
                className="pagination__item pagination__item--next"
              >
                <Icons.ChevronRight />
              </Link>
            </div>
          </div>
          <div className="lg:col-start-12 lg:col-span-12">
            <Sidebar recentPost={data.recentPost.edges} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PostsPage;

export const pageQuery = graphql`
  query ($language: String!, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "post" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            brief
          }
        }
      }
    }
    recentPost: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "post" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            brief
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
